import { QUOTATION_STATES } from "../../constants/quotationStates";
import { resetClients } from './appDataRedux'
import { v4 as uuidv4 } from 'uuid';
import {
  httpApiPost,
  httpApiPatch,
  httpApiGetPaginated,
} from "../helpers/axiosPrivate";
import { removeLocalStorage, setLocalStorage } from "../../auth/helpers";
import { PRODUCT_QUOTATIONS, QUOTATION_DATA } from "../../constants/LocalStorageItems";

const initialQuotationProduct = {
  id: null,
  temporalId: null,
  product: null,
  costPerUnit: "",
  units: "",
  markUp: "",
  externalVariantId: "",
  price: "",
  logoCost: 0,
  surcharge: "",
  sellingPrice: ""
};

export const initialQuotationData = {
  id: null,
  clientId: "",
  clientName: "",
  name: "",
  lastname: "",
  email: "",
  position: "",
  action: "",
  referentId: null,
  paymentConditionId: "",
  orderNoteId: "",
  invoiceQuantity: "",
};

const initialState = {
  quotationData: {
    id: null,
    clientId: "",
    clientName: "",
    name: "",
    lastname: "",
    email: "",
    position: "",
    action: "",
    referentId: null,
    paymentConditionId: "",
    orderNoteId: "",
    invoiceQuantity: "",
  },
  quotationProduct: {
    id: null,
    temporalId: null,
    product: null,
    costPerUnit: "",
    units: "",
    markUp: 0,
    externalVariantId: "",
    price: "",
    logoCost: 0,
    surcharge: "",
    sellingPrice: ""
  },
  productQuotations: [],
  quotations: {
    [QUOTATION_STATES.OPEN]: null,
    [QUOTATION_STATES.APPROVED]: null,
    [QUOTATION_STATES.DELETED]: null,
    [QUOTATION_STATES.AUTHORIZATION_REQUEST]: null,
  },
  loading: false,
  loadingOverlay: false,
  error: false,
  message: "",
  showModalProducts: false,
  internalProduct: false,
};

const SET_QUOTATIONS = "SET_QUOTATIONS";
const SET_QUOTATION_DATA = "SET_QUOTATION_DATA";
const RESET_QUOTATION_DATA = "RESET_QUOTATION_DATA";
const SET_QUOTATION_PRODUCT = "SET_QUOTATION_PRODUCT";
const RESET_QUOTATION_PRODUCT = "RESET_QUOTATION_PRODUCT";
const SET_QUOTATION_PRODUCT_QUOTATIONS = "SET_QUOTATION_PRODUCT_QUOTATIONS";
const START_LOADING_QUOTATIONS = "START_LOADING_QUOTATIONS";
const STOP_LOADING_QUOTATIONS = "STOP_LOADING_QUOTATIONS";
const RESET_QUOTATIONS_MESSAGE = "RESET_QUOTATIONS_MESSAGE";
const SHOW_QUOTATIONS_MESSAGE = "SHOW_QUOTATIONS_MESSAGE";
const MANAGE_MODAL_PRODUCTS = "MANAGE_MODAL_PRODUCTS";
const SET_IS_SUBMITTING = "SET_IS_SUBMITTING";
const START_LOADING_QUOTATIONS_OVERLAY = "START_LOADING_QUOTATIONS_OVERLAY";
const STOP_LOADING_QUOTATIONS_OVERLAY = "STOP_LOADING_QUOTATIONS_OVERLAY";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_QUOTATIONS:
      return {
        ...state,
        quotations: action.payload,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        quotationData: action.payload,
      };
    case RESET_QUOTATION_DATA:
      return {
        ...state,
        quotationData: initialQuotationData,
      };
    case SET_QUOTATION_PRODUCT:
      return {
        ...state,
        quotationProduct: action.payload,
      };
    case RESET_QUOTATION_PRODUCT: {
      return {
        ...state,
        quotationProduct: initialQuotationProduct,
      };
    }
    case SET_QUOTATION_PRODUCT_QUOTATIONS:
      return {
        ...state,
        productQuotations: action.payload,
      };
    case START_LOADING_QUOTATIONS:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_QUOTATIONS:
      return {
        ...state,
        loading: false,
      };
    case SHOW_QUOTATIONS_MESSAGE:
      return {
        ...state,
        message: action.message,
        error: action.payload,
      };
    case RESET_QUOTATIONS_MESSAGE:
      return {
        ...state,
        message: "",
        error: false,
      };
    case MANAGE_MODAL_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };
    case START_LOADING_QUOTATIONS_OVERLAY:
      return {
        ...state,
        loadingOverlay: true,
      };
    case STOP_LOADING_QUOTATIONS_OVERLAY:
      return {
        ...state,
        loadingOverlay: false,
      };
    default:
      return state;
  }
}

export const getQuotations =
  (data, withLoading = false) =>
  async (dispatch, getState) => {
    let currentState = { ...getState().quotation.quotations };

    if ((data.state && !currentState[data.state]) || withLoading) {
      dispatch({
        type: START_LOADING_QUOTATIONS,
      });
    }
    try {
      const quotations = await httpApiGetPaginated("quotations", data);
      if (quotations) {
        currentState = { ...getState().quotation.quotations };
        const payload = { ...currentState, [data.state]: quotations };
        dispatch({
          type: SET_QUOTATIONS,
          payload,
        });
      }
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    } finally {
      if ((data.state && !currentState[data.state]) || withLoading) {
        dispatch({
          type: STOP_LOADING_QUOTATIONS,
        });
      }
    }
  };

export const setQuotation = (quotation) => async (dispatch, getState) => {
  dispatch({
    type: RESET_QUOTATION_DATA,
  });
  try {
    let data = (({ productQuotations, client, ...o }) => o)(quotation);
    data.clientId = quotation.client.id;
    data.clientName = quotation.client.name;
    dispatch({
      type: SET_QUOTATION_DATA,
      payload: data,
    });

    let productQuotationsAux = [];
    for (let productQuotation of quotation.productQuotations) {
      const externalVariantId = productQuotation?.variant?.id;
      productQuotation = (({ variant, ...o }) => o)(productQuotation);
      if (externalVariantId) {
        productQuotation.externalVariantId = externalVariantId;
      }
      if (!productQuotation.temporalId) {
        productQuotation.temporalId = uuidv4();
      }
      productQuotationsAux.push(productQuotation);
    }
    setLocalStorage(PRODUCT_QUOTATIONS, productQuotationsAux)
    dispatch({
      type: SET_QUOTATION_PRODUCT_QUOTATIONS,
      payload: productQuotationsAux,
    });
  } catch (error) {
    dispatch({
      type: SHOW_QUOTATIONS_MESSAGE,
      message: error.message,
      payload: true,
    });
  }
};

const getDefaultExternalVariantID = (product, getState) => {
  const externalVariantIds = getState()
    .quotation.productQuotations.filter(
      (quotation) => quotation.product.id === product.id
    )
    .map((quotation) => quotation.externalVariantId);
  for (const variant of product.variants) {
    // if (!externalVariantIds.includes(variant.id)) {
    //   return variant.id;
    // }
    return variant.id;
  }
};

export const setQuotationProductProduct =
  (product) => async (dispatch, getState) => {
    dispatch({
      type: RESET_QUOTATION_PRODUCT,
    });

    let quotationProductInitial = { ...initialQuotationProduct };
    quotationProductInitial["product"] = product;
    if (product && product?.internalProductId) {
      quotationProductInitial["internalProductId"] = product.id;
      quotationProductInitial["product"]["price"] = 0;
      quotationProductInitial["costPerUnit"] = 0;
      quotationProductInitial["units"] = 1;
      quotationProductInitial["image"] = product.image;
      dispatch({
        type: SET_QUOTATION_PRODUCT,
        payload: quotationProductInitial,
      });
      dispatch(setShowModalProducts(true, true));
      return;
    } else {
      const defaultExternalVariantId = getDefaultExternalVariantID(
        product,
        getState
      );

      if (defaultExternalVariantId) {
        dispatch({
          type: SET_QUOTATION_PRODUCT,
          payload: quotationProductInitial,
        });
        dispatch(setShowModalProducts(true));
      } else {
        dispatch({
          type: SHOW_QUOTATIONS_MESSAGE,
          message: "No se pudo agregar el producto a la cotización",
          payload: true,
        });
      }
    }
  };

export const setQuotationProduct =
  (quotationProduct) => async (dispatch, getState) => {
    if (quotationProduct.variant) {
      const externalVariantId = quotationProduct.variant.id;
      quotationProduct = (({ variant, ...o }) => o)(quotationProduct);
      quotationProduct.externalVariantId = externalVariantId;
    }
    try {
      dispatch({
        type: SET_QUOTATION_PRODUCT,
        payload: quotationProduct,
      });
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    }
  };

export const setQuotationDataProperty =
  (property, value) => async (dispatch, getState) => {
    let currentState = { ...getState().quotation.quotationData };
    currentState[property] = value;
    setLocalStorage(QUOTATION_DATA, currentState)
    try {
      dispatch({
        type: SET_QUOTATION_DATA,
        payload: currentState,
      });
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    }
  };

export const setAllQuotationData = (storedQuotationData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_QUOTATION_DATA,
      payload: storedQuotationData,
    });
  } catch (error) {
    dispatch({
      type: SHOW_QUOTATIONS_MESSAGE,
      message: error.message,
      payload: true,
    });
  }
};
export const setQuotationProductProperty =
  (property, value) => async (dispatch, getState) => {
    let currentState = { ...getState().quotation.quotationProduct };
    if (property === "product" && isNaN(value)) {
      return;
    }

    currentState[property] = value;
    try {
      dispatch({
        type: SET_QUOTATION_PRODUCT,
        payload: currentState,
      });
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    }
  };
  
  export const setProductQuotations  = (productQuotations) =>
    async (dispatch) => {

      
      dispatch({
        type: SET_QUOTATION_PRODUCT_QUOTATIONS,
        payload: productQuotations,
      });
  }
export const addQuotationToQuotations =
  (productQuotation, removeVariant, originalExternalVariantId, originalName) =>
  async (dispatch, getState) => {
    try {
      let filter = [];
      let currentState = getState().quotation.productQuotations;
      if (productQuotation.externalVariantId) {
        filter = currentState

        if (removeVariant) {
          filter = filter.filter(
            (q) => q.temporalId !== productQuotation.temporalId
          );
        }
      } else {
        if (productQuotation.product.id) {
          filter = currentState.filter(
            (q) =>
              q.product.id !== productQuotation.product.id ||
              q.externalVariantId
          );
        } else {
          filter = currentState.filter(
            (q) =>
              q.product.name !== originalName ||
              q.externalVariantId
          );
          filter = currentState
        }
      }
      if(productQuotation.temporalId) {
        filter = filter.filter(
          (q) => q.temporalId !== productQuotation.temporalId
        );
      }
      if (!productQuotation.temporalId) {
        productQuotation.temporalId = uuidv4();
      }
      filter.push(productQuotation);
      setLocalStorage(PRODUCT_QUOTATIONS, filter)
      dispatch({
        type: SET_QUOTATION_PRODUCT_QUOTATIONS,
        payload: filter,
      });
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: "Producto agregado correctamente",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    }
  };

export const resetQuotation = () => async (dispatch, getState) => {
  try {
    dispatch(resetQuotationData());
    dispatch(resetQuotationProduct());
    dispatch({
      type: SET_QUOTATION_PRODUCT_QUOTATIONS,
      payload: [],
    });
    dispatch(resetQuotationsMessage());
  } catch (error) {
    dispatch({
      type: SHOW_QUOTATIONS_MESSAGE,
      message: error.message,
      payload: true,
    });
  }
};

export const saveQuotation = (successAction, errorAction) => async (dispatch, getState) => {
  let quotationData = getState().quotation.quotationData;
  let productQuotations = getState().quotation.productQuotations;

  productQuotations = productQuotations.map((productQuotation) => {
    if (productQuotation.temporalId) {
      productQuotation = (({ temporalId, ...o }) => o)(productQuotation);
    }
    if (productQuotation.sellingPrice) {
      productQuotation = (({ sellingPrice, ...o }) => o)(productQuotation);
    }
    return productQuotation;
  });

  try {
    const data = {
      ...quotationData,
      productQuotations,
    };

    const formData = new FormData();
    
    const addToFormData = (data, formData, parentKey = '') => {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];
          const formKey = parentKey ? `${parentKey}[${key}]` : key;
    
          if (key === 'productQuotations' && Array.isArray(value)) {
            value.forEach((item, index) => {
              const arrayFormKey = `${formKey}[${index}]`;
              addToFormData(item, formData, arrayFormKey);
            });
          } else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            if (value instanceof File) {
              formData.append(key, value);
            } else {
              addToFormData(value, formData, formKey);
            }
          } else {
            if (value !== null && value !== '') {
              formData.append(formKey, value);
            }
          }
        }
      }
    };
    
    addToFormData(data, formData);

    if(quotationData.position === "") {
      formData.append('position', "" )
    }

    // Imprimir el formData en la consola
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    if (quotationData.id) {
      dispatch({
        type: SET_IS_SUBMITTING,
        payload: true,
      });

      await httpApiPatch(`quotations/update/${quotationData.id}`, formData);
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: "Cotización editada correctamente",
        payload: false,
      });
    } else {
      dispatch({
        type: SET_IS_SUBMITTING,
        payload: true,
      });

      await httpApiPost("quotations/create", formData);
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: "Cotización creada correctamente",
        payload: false,
      });
    }

    dispatch(resetClients());
    dispatch(resetQuotation());
    dispatch(resetQuotationProduct(true));
    successAction();
  } catch (error) {
    dispatch({
      type: SHOW_QUOTATIONS_MESSAGE,
      message: error.message,
      payload: true,
    });
    
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: false,
    });
  }
};

export const removeQuotationFromQuotations =
  (productQuotation) => async (dispatch, getState) => {
    try {
      let currentState = getState().quotation.productQuotations;
      let filter = [];
      if(productQuotation.temporalId) {
        filter = currentState.filter(
          (q) => q.temporalId !== productQuotation.temporalId
        );
      } else if (productQuotation.product.id) {
        filter = currentState.filter(
          (q) => q.product.id !== productQuotation.product.id
        );
      }

      // if (productQuotation.externalVariantId) {
      //   filter = currentState.filter(
      //     (q) => q.externalVariantId !== productQuotation.externalVariantId
      //   );
      // } else {
      //   if (productQuotation.product.id) {
      //     filter = currentState.filter(
      //       (q) =>
      //         q.product.id !== productQuotation.product.id ||
      //         q.externalVariantId
      //     );
      //   } else {
      //     filter = currentState.filter(
      //       (q) =>
      //         q.product.name !== productQuotation.product.name ||
      //         q.externalVariantId
      //     );
      //   }
      // }

      setLocalStorage(PRODUCT_QUOTATIONS, filter)
      dispatch({
        type: SET_QUOTATION_PRODUCT_QUOTATIONS,
        payload: filter,
      });
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: "Producto eliminado correctamente",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: SHOW_QUOTATIONS_MESSAGE,
        message: error.message,
        payload: true,
      });
    }
  };

export const setShowModalProducts =
  (action, internalProduct = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: MANAGE_MODAL_PRODUCTS,
      payload: {
        showModalProducts: action,
        internalProduct,
      },
    });
  };

  export const setStartLoadingOverlay = () => async (dispatch, getState) => {
    dispatch({
      type: START_LOADING_QUOTATIONS_OVERLAY,
    });
  };

  export const setStopLoadingOverlay = () => async (dispatch, getState) => {
    dispatch({
      type: STOP_LOADING_QUOTATIONS_OVERLAY,
    });
  };

export const resetQuotationData = () => async (dispatch, getState) => {
  removeLocalStorage(QUOTATION_DATA)
  dispatch({
    type: RESET_QUOTATION_DATA,
  });
};

export const resetQuotationProduct = (removeLocalFromStorage = false) => async (dispatch, getState) => {
  if (removeLocalFromStorage) {
    removeLocalStorage(PRODUCT_QUOTATIONS)
  }
  dispatch({
    type: RESET_QUOTATION_PRODUCT,
  });
};

export const resetQuotationProductQuotation = () => async (dispatch, getState) => {
  dispatch({
    type: SET_QUOTATION_PRODUCT_QUOTATIONS,
    payload: []
  });
};

export const resetQuotationsMessage = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_QUOTATIONS_MESSAGE,
  });
};
